import React from 'react';
import PropTypes from 'prop-types';

import { isEmpty, size } from 'theme/utils/functions';

import spacings from 'theme/spacings';
import ArticleGrid from 'components/common/ArticleGrid';
import Grid from 'components/foundation/layout/Grid';
import StaticBanner from 'components/common/StaticBanner';

const DynamicCategoriesList = ({ data, isMobile }) => (
  <>
    {data?.map((item, index) => {
      const categoryInfo = item?.categoria;

      const { news } = categoryInfo || {};

      const totalNews = size(news);

      if (isEmpty(news)) {
        return null;
      }

      return (
        <React.Fragment key={categoryInfo?.link}>
          {index === 1 ? (
            <Grid.Container>
              <Grid.Row $cssPadding={{ xs: `${spacings.md} 0 0` }}>
                <Grid.Col $colValue={{ xs: 12 }}>
                  <StaticBanner
                    bannerFormat='horizontalLarge'
                    title='Betnacional - A Bet do Brasil'
                    mobileImage='https://media.nsxafiliados.com/uploads/001xONEFOOTBALL-400x300px.gif'
                    desktopImage='https://media.nsxafiliados.com/uploads/970x250px_1.gif'
                    link='https://record.nsxafiliados.com/_bG5FcVwwsuvfkb3gg_bGdGNd7ZgqdRLk/1/'
                  />
                </Grid.Col>
              </Grid.Row>
            </Grid.Container>
          ) : null}

          <ArticleGrid
            articles={news}
            isMobile={isMobile}
            limit={totalNews > 4 ? 4 : totalNews}
            articleProps={{
              thumbnailSize: 'small',
              titleTypography: 'heading3',
            }}
            sectionTitleProps={{
              title: categoryInfo?.nome,
              viewMore: {
                link: categoryInfo?.link,
              },
            }}
            {...(categoryInfo?.nome === 'Fatos do Confronto'
              ? {
                  limit: 100,
                  enableSelect: true,
                }
              : {
                  enableSelect: false,
                })}
          />
        </React.Fragment>
      );
    })}
  </>
);

DynamicCategoriesList.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      categoria: PropTypes.shape({
        link: PropTypes.string,
        nome: PropTypes.string,
        news: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            noticia: PropTypes.object,
            thumbnail: PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.shape({}),
            ]),
          })
        ),
      }),
    })
  ).isRequired,
};

export default React.memo(DynamicCategoriesList);
